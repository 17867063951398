// TODO: Move PaymentHeader do v2 components and change name to DirectPaymentMethodHeader.
import SubscriptionPaymentSection from "@components/payment/SubscriptionPaymentSection";
import GiftCardContainer from "@components/payment/giftCard/GiftCardContainer";
import { Divider } from "@components/v2/components/dividers/Divider";
import DirectPaymentMethods from "@components/v2/components/payments/DirectPaymentMethods";
import PaymentAlert from "@components/v2/components/payments/PaymentAlert";
import BonusPointsSection from "@components/v2/components/payments/methods/BonusPointsSection";
import selectPaymentMethodVisibility from "@components/v2/components/payments/selectors/selectPaymentMethodVisibility";
import Section from "@components/v2/components/section/Section";
import {
	SectionHeader,
	SectionContent,
	SectionLabel,
	SectionNumber,
} from "@components/v2/components/section/section.styles";
import { SECTIONS_NAMES } from "@constants/constants";
import { GTM_EVENT_NAMES, pushToDataLayerAsync } from "@utils/GTMUtils";
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { useElementObserver } from "../../../../customHooks/useElementObserver";
import usePaymentValidation from "../../../../customHooks/usePaymentValidation";

const Payment = () => {
	const paymentRef = useRef(null);
	const isRenewalMode = useSelector((state) => state.checkout.isRenewalMode);
	const isPaymentSectionValid = useSelector(
		(state) => state.payment.isPaymentSectionValid,
	);
	const isHidden = useSelector((state) => state.payment.isHidden);
	const methodsOrder = useSelector(
		(state) => state.payment.directMethodsOrder || [],
	);

	const {
		isDirectPaymentMethodsVisible,
		isCoveredByBonusVisible,
		isGiftCardVisible,
		isSubscriptionMethodVisible,
	} = useSelector(selectPaymentMethodVisibility);

	const { formatMessage } = useIntl();

	usePaymentValidation();

	const sectionLabelId = isRenewalMode
		? "payment.section.title.renewal"
		: "payment.section.title";

	useElementObserver(paymentRef, () => {
		pushToDataLayerAsync({
			data: {
				event: GTM_EVENT_NAMES.PAYMENT_VISITED,
				options: methodsOrder.join(", "),
			},
		});
	});

	if (isHidden) {
		return null;
	}

	return (
		<Section
			id={SECTIONS_NAMES.PAYMENT}
			className="section-payment"
			ref={paymentRef}
		>
			<SectionHeader>
				{!isRenewalMode && <SectionNumber>{3}</SectionNumber>}
				<SectionLabel>{formatMessage({ id: sectionLabelId })}</SectionLabel>
			</SectionHeader>
			<Divider />

			<SectionContent>
				<fieldset aria-invalid={!isPaymentSectionValid}>
					{isDirectPaymentMethodsVisible && <DirectPaymentMethods />}
					{isCoveredByBonusVisible && <BonusPointsSection />}
					{isGiftCardVisible && <GiftCardContainer />}
				</fieldset>
				{isSubscriptionMethodVisible && <SubscriptionPaymentSection />}
				<PaymentAlert />
			</SectionContent>
		</Section>
	);
};

export default Payment;
