import FlexSection from "@components/payment/SubscriptionPayment/FlexSection";
import FinancePaymentMethod from "@components/payment/methods/financePaymentMethod/FinancePaymentMethod";
import { PAYMENT_METHOD_NAMES } from "@constants/constants";
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Panel from "./Panel";
import PaymentHeader from "./PaymentHeader";

const SubscriptionPaymentSection = () => {
	const { formatMessage } = useIntl();

	const isFairownSubscription = useSelector(
		(state) =>
			state.payment.subscriptionMethod.type ===
			PAYMENT_METHOD_NAMES.FAIROWN_SUBSCRIPTION,
	);
	const subscriptionMethod = useSelector(
		(state) => state.payment.subscriptionMethod,
	);

	const isDisabled = useSelector(
		(state) => state.payment.isSectionDisabled || state.checkout.isDisabled,
	);

	return (
		<section
			className="section-payment-subscription"
			aria-label={formatMessage({
				id: "monthly.payment.section.title",
			})}
		>
			<div className="content">
				<Panel
					key={subscriptionMethod.type}
					id={subscriptionMethod.type}
					automationid={subscriptionMethod.type}
					automationIdSection="monthlyPayment"
					active={true}
					hiddenHeader={true}
					header={<PaymentHeader method={subscriptionMethod} />}
					shouldRemoveGiftCard={false}
					onActivate={() => {
						/* no action needs to be taken */
					}}
					disabled={isDisabled}
				>
					{isFairownSubscription ? (
						<FlexSection />
					) : (
						<FinancePaymentMethod
							type={subscriptionMethod.type}
							version={subscriptionMethod.version}
						/>
					)}
				</Panel>
			</div>
			<div className="split-payment-divider" />
		</section>
	);
};

export default SubscriptionPaymentSection;
